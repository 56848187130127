import React, {useEffect, useState} from "react";
import Crud from "../helpers/Crud";
const Login =()=> {
    const [credenciales, setCredenciales]= useState({
        "username": "",
        "password": ""
      }); 
    const [showPassword, setShowPassword] = useState(false);  
    const [showForgot, setShowForgot] = useState(false);
    const [message, setMessage]= useState("");
  useEffect(() => {
  },[]);
  const handleLogin=()=>{
    Crud("auth/login", "post", credenciales, "").then(res => {
        if( res.access_token ){
          sessionStorage.setItem("token", res.access_token);
          window.location.href="/";
        }        
      }).catch(err => {
        setMessage("A ocurrido un error intenta de nuevo o mas tarde ..!!");
      })
  }
  return (
    <div className="login-bg d-flex align-items-center">
        <div className="container">
            <div className="row">
                <div className="col-lg-4 col-md-6 col-sm-8">
                    <div className="login">

                      <img src="/images/logo.png" className="img-fluid px-5 py-3" alt="lambicall" /> 

                      <p className="my-2">Por favor, ingresa tus credenciales para acceder a tu cuenta.</p>

                      <div className="form-floating mb-3">
                        <input type="email" className="form-control" id="usuario" placeholder="correo@empresa.com"
                            value={credenciales.username} onChange={(e) => setCredenciales({...credenciales, username: e.target.value})}
                        />
                        <label htmlFor="usuario">Usuario</label>
                      </div>
                      <div className="form-floating">
                        <input type={showPassword? "text":"password"} className="form-control password" id="password" placeholder="********"
                            value={credenciales.password} onChange={(e) => setCredenciales({...credenciales, password: e.target.value})}
                        />
                        <label htmlFor="password">Contraseña</label>
                        <i className={"fa-solid fa-eye"+(showPassword?"":"-slash")+" togglePassword password-eye"}
                            onClick={()=>setShowPassword(!showPassword)}
                        ></i>
                      </div>
                      { message && message !== "" ?
                          <span className="text-danger">{message}</span>
                          :"" 
                      }
                      <div className="form-check form-switch my-4">
                        <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={showForgot}
                            onChange={()=>setShowForgot(!showForgot)}
                        />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Recordar</label>
                      </div>
  


                      <div id="forgot" className="my-3">
                        <a className="link-bs" href="/change_password">Olvidaste tu contraseña</a>
                      </div>


                      <button type="button" className="btn btn-primary w-100"
                        onClick={handleLogin}
                      >Iniciar Sesión</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
  );
}
export default Login;