import React, {useEffect, useState} from "react";
const Sidebar =()=> {
const [isCollapsed, setIsCollapsed] = useState({
        Dashboard: true,
        Consola: true,
        Catalogos: true,
        CallCenter: true,
        PBX: true,
        Reportes: true,
        Sistema: true,
    }
);
  useEffect(() => {
  },[]);

  return (
    <ul className="sidebar-nav">
        <li className="sidebar-item active">
            <a className="sidebar-link" href="/">
                <i className="fa-solid fa-house align-middle"></i><span className="align-middle">Dashboard</span>
            </a>            
        </li>
        <li className="sidebar-item">
            <a className="sidebar-link" href="consola.html">
                <i className="fa-solid fa-laptop align-middle"></i><span className="align-middle">Consola</span>
            </a>
        </li>
        <li className="sidebar-item">
            <a data-bs-target="#catalogos" data-bs-toggle="collapse" className="sidebar-link" onClick={()=> setIsCollapsed({...isCollapsed, Catalogos: !isCollapsed.Catalogos})}>
                <i className="fa-solid fa-book-bookmark align-middle"></i><span className="align-middle">Catálogos</span>
            </a>
            { !isCollapsed.Catalogos &&
                <ul id="catalogos" className="sidebar-dropdown list-unstyled" data-bs-parent="#sidebar">
                    <li className="sidebar-item Sidebar-active"><a className="sidebar-link" href="/usuarios">Usuarios</a></li>
                    <li className="sidebar-item"><a className="sidebar-link" href="catalogo-tipo-gestion.html">Tipo de Gestión</a></li>
                    <li className="sidebar-item"><a className="sidebar-link" href="catalogo-tipo-resultado.html">Tipo de Resultado </a></li>
                    <li className="sidebar-item"><a className="sidebar-link" href="catalogo-motivos.html">Motivos</a></li>
                    <li className="sidebar-item"><a className="sidebar-link" href="catalogo-audio.html">Audio</a></li>
                </ul>
            }
        </li>
        <li className="sidebar-item">
            <a data-bs-target="#callCenter" data-bs-toggle="collapse" className="sidebar-link" onClick={()=> setIsCollapsed({...isCollapsed, CallCenter: !isCollapsed.CallCenter})}>
                <i className="fa-solid fa-headset align-middle"></i> <span className="align-middle">Call Center</span>
            </a>
            { !isCollapsed.CallCenter && //<ul id="callCenter" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                <ul id="callCenter" className="sidebar-dropdown list-unstyled" data-bs-parent="#sidebar">
                    <li className="sidebar-item"><a className="sidebar-link" href="/callCenter-cliente">Cliente</a></li>
                    <li className="sidebar-item"><a className="sidebar-link" href="callCenter-cartera.htm">Cartera </a></li>
                    <li className="sidebar-item"><a className="sidebar-link" href="callCenter-campaña.htm">Campaña</a></li>
                    <li className="sidebar-item"><a className="sidebar-link" href="callCenter-template.htm">Template</a></li>
                </ul>
            }
        </li>
        <li className="sidebar-item">
            <a className="sidebar-link" href="pbx.html">
                <i className="fa-solid fa-phone-volume"></i> <span className="align-middle">PBX</span>
            </a>
        </li>
        <li className="sidebar-item">
            <a className="sidebar-link" href="pbx.html">
                <i className="fa-solid fa-chart-line"></i> <span className="align-middle">Reporte de Llamadas</span>
            </a>
        </li>
        <li className="sidebar-item">
            <a className="sidebar-link" href="pbx.html">
                <i className="fa-solid fa-gears"></i> <span className="align-middle">Sistema</span>
            </a>
        </li>        
    </ul>
  );
}
export default Sidebar;