import { BrowserRouter, Routes, Route } from "react-router-dom";
import { validarToken } from "./helpers/JWT";
import { useEffect } from "react";
import Login from "./pages/Login";
import DashboardPrincipal from "./pages/DashboardPrincipal";
import { UsuariosPage } from "./pages/catalogos";
import { ClientesPage } from "./pages/callCenter";
const url = new URL(window.location.href);
const App =()=> {
  useEffect (()=> {
    validarToken();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DashboardPrincipal />}></Route>
        <Route path="/login" element={<Login />} />
        <Route path="/usuarios" element={<UsuariosPage />} />
        <Route path="/callCenter-cliente" element={<ClientesPage />} />      
      </Routes>
    </BrowserRouter>
  );
}

export default App;
//const root = ReactDOM.createRoot(document.getElementById('root'));
//root.render(<App />);
//export default App;
