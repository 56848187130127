import axios from 'axios';
import { validarToken } from "./JWT";

/**
 * 
 * @param {text* enponit} endpoint 
 * @param {text*get,post,put,delete} metodo 
 * @param {JSON* OBJECT} params 
 * @param {text* id} id 
 * @returns 
 */
export function Crud(endpoint="", metodo="get", params=[], id=""){
  let instance = axios.create();
  if(sessionStorage.getItem('token')){
    instance.defaults.headers.common['Authorization'] = 'Bearer '+ sessionStorage.getItem('token');
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    instance.defaults.headers.common['Accept'] = 'application/json';  
  }
  validarToken();
  switch(metodo){
    case "get":
      return instance.get(process.env.REACT_APP_API_URL+endpoint).then(res => {
        return res.data;
      });
    case "post":
      return instance.post(process.env.REACT_APP_API_URL+endpoint, params).then(res => {
        return res.data;
      });/*.catch(err => {
        return faerrlse;
      });*/
    case "put":
      return instance.put(process.env.REACT_APP_API_URL+endpoint, params).then(res => {
        return res.data;
      });
    case "delete":
      return instance.delete(process.env.REACT_APP_API_URL+endpoint+"/"+id, params).then(res => {
        return res.data;
      });
  }
}

export default Crud;
