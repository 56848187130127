import React, {useEffect, useState} from "react";
import Crud from "../../../helpers/Crud";

const UsuarioForm =({ objeto, onSave })=> {
  const [data, setData]= useState({
    "name": "",
    "lastname": "",
    "email": "",
    "username": "",
    "password": ""
  });
  useEffect(() => {
    if( objeto && 'id' in objeto){
      setData({
        "name": objeto.name,
        "lastname": objeto.lastname,
        "email": objeto.email,
        "username": objeto.username,
        "password": objeto.password
      });
    }
  },[objeto]);
  /**
   * Crud("user", "get", "", "").then(res => {
      if( res && res.length ){
        setData(res);
      }
      
    }).catch(err => {
      console.log("A ocurrido un error intenta de nuevo o mas tarde ..!!");
    });
   */
  return (
    <div className="row g-3 align-items-end">
      <div className="col-lg-12 my-2">
        <div className="form-floating">
            <input type="text" className="form-control" id={"name"+(objeto && objeto.id? objeto.id:"")} placeholder="Nombre"
              value={data.name} onChange={(e) => setData({...data, name: e.target.value})}
            />
            <label htmlFor="name">Nombre</label>
        </div>
      </div>
      <div className="col-lg-12 my-2">
        <div className="form-floating">
            <input type="text" className="form-control" id={"lastname"+(objeto && objeto.id? objeto.id:"")} placeholder="Apellidos"
              value={data.lastname} onChange={(e) => setData({...data, lastname: e.target.value})}
            />
            <label htmlFor="lastname">Apellidos</label>
        </div>
      </div>
      <div className="col-lg-12 my-2">
        <div className="form-floating">
            <input type="email" className="form-control" id={"email"+(objeto && objeto.id? objeto.id:"")} placeholder="name@example.com"
              value={data.email} onChange={(e) => setData({...data, email: e.target.value})}
            />
            <label htmlFor="email">Correo</label>
        </div>
      </div>
      <div className="col-lg-12 my-2">
        <div className="form-floating">
            <input type="text" className="form-control" id={"username"+(objeto && objeto.id? objeto.id:"")} placeholder="Usuario"
              value={data.username} onChange={(e) => setData({...data, username: e.target.value})}
            />
            <label htmlFor="username">Usuario</label>
        </div>
      </div>
      <div className="col-lg-12 my-2">
        <div className="form-floating">
            <input type="password" className="form-control" id={"password"+(objeto && objeto.id? objeto.id:"")} placeholder="********"
              value={data.password} onChange={(e) => setData({...data, password: e.target.value})}
            />
            <label htmlFor="password">Contraseña</label>
        </div>
      </div>
      <div className="col-lg-12 my-4">
          <button className="btn btn-primary btn-lg w-100"
            onClick={()=>
              Crud("user"+(objeto && 'id' in objeto? ("/"+objeto.id):""), (objeto && 'id' in objeto? "put":"post"), data, "")
              .then(res => {
                if( onSave ){
                  onSave();
                }
              }).catch(err => {
                console.log("A ocurrido un error intenta de nuevo o mas tarde ..!!");
              })
            }
          >
            <i className="fa-solid fa-save me-1"></i>
            Guardar
          </button>
      </div>
    </div>
  );
}
export default UsuarioForm;