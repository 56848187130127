import React, {useEffect, useState} from "react";

const Modal =({clave, boton, titulo, body, footer})=> {
  const [data, setData]= useState([]);
  useEffect(() => {
  },[]);
  return (
    <>
        <span className="btn" data-bs-toggle="modal" data-bs-target={"#"+clave}>{boton}</span>
        {/*}
        <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target={"#"+key}><i className="fa-solid fa-circle-plus me-1"></i> Nuevo Usuario </button>
        */}
        <div className="modal fade" id={clave} tabIndex="-1" aria-labelledby={clave} aria-hidden="true">
            <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                <h1 className="modal-title fw-bold fs-4">{titulo}</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" id={"close"+clave} aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    { body }
                </div>
                <div className="modal-footer">
                    { footer }
                </div>
            </div>
            </div>
        </div>
    </>
  );
}
export default Modal;