import React, { useState } from "react";
import {Sidebar} from "./";
const  CatalogosPlantilla =({ children, catalogoNombre })=> {
const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  return (
    <div>
      <div className="wrapper">
        { isSidebarOpen && (
            <nav id="sidebar" className="sidebar js-sidebar">
                <div className="sidebar-content js-simplebar">
                    <a className="sidebar-brand" href="index.html">
                        <img src="/images/logo-w.png" className="img-fluid p-3" />
                    </a>
                    <div w3-include-html="sidebar.html"></div>
                    <Sidebar />              
                </div>
            </nav>
        )}
        <div className="main">
            <nav className="navbar navbar-expand navbar-light navbar-bg">
                <a className="sidebar-toggle js-sidebar-toggle" onClick={()=> setIsSidebarOpen(!isSidebarOpen)}>
                    <i className="hamburger align-self-center"></i>
                </a>
                <div className="bradecrumb">Catálogos / {catalogoNombre}</div>
                <div className="navbar-collapse collapse">
                    <ul className="navbar-nav navbar-align">

                        <div w3-include-html="header.html"></div>
                      
                    </ul>
                </div>                
            </nav>
            { children }           
        </div>
      </div>      
    </div>
  );
}
export default CatalogosPlantilla;