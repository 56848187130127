//{ "sub": "pamiro", "scopes": "root", "iat": 1670460763, "exp": 1670503963 }
export function JWT(){
}
export function validarToken () {
  const paginasPublicas= ['login'];
  const url = new URL(window.location.href);

  const token= getTokenToJson();
  for( let pag of paginasPublicas){
    if(url.pathname.includes(pag))
      return true;
  }
  if(token){
    if(new Date() > new Date(token.exp*1000) ){//caduco
      sessionStorage.removeItem("token");
      return false;
    }
  }else{//No hay token
    window.location.href="/login";
    //return false
  }
};

export function GetUser () {
  const token= getTokenToJson();
  if(token)
    return token.sub;
  else
    return "";
};
export function GetRoles () {
  const token= getTokenToJson();
  if(token){
    if(new Date() > new Date(token.exp*1000) ){
      return [];
    }
    return token.scopes.split(",");
  }
  return [];
};
function getTokenToJson () {
  const token= sessionStorage.getItem("token");
  if(token){
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) { return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2); }).join(''));
    return JSON.parse(jsonPayload);
  }else{
    return null
  }
};
export default JWT;
