import React, {useEffect, useState} from "react";
import Crud from "../../../helpers/Crud";
import { Modal, TablePageable } from "../../../helpers";
import UsuarioForm from "./UsuarioForm";
import { CatalogosPlantilla } from "../../../componentes";

const UsuariosPage =()=> {
  const [data, setData]= useState([]);
  useEffect(() => {
    obtenerUsuarios();
  },[]);
  const obtenerUsuarios=()=>{
    Crud("user", "get", "", "").then(res => {
      if( res && res.length ){
        setData(res);
      }
    }).catch(err => {
      console.log("A ocurrido un error intenta de nuevo o mas tarde ..!!");
    })
  }
  return (
    <CatalogosPlantilla catalogoNombre="Usuarios">
      <main className="content">
        <h1 className="title"><i className="fa-solid fa-book-bookmark me-2"></i>Usuarios</h1>
        <div className="row my-4">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="justify-content-end d-flex">
                          <Modal
                            clave={"nuevoUsuario"}
                            boton={
                              <button className="btn btn-primary">
                                <i className="fa-solid fa-circle-plus me-1"></i> Crear usuario
                              </button>}
                            titulo={"Nuevo Usuario"}
                            body={
                              <UsuarioForm
                                objeto={null}
                                onSave={()=>{
                                  obtenerUsuarios();
                                  document.getElementById("close"+("nuevoUsuario")).click();
                                }}
                              />}
                            footer={<>Verifica antes de guardar ...!</>}
                          />
                        </div>
                    </div>
                  
                    <div className="card-body">
                    <TablePageable
                      //titulo={<h1 className="title"><i className="fa-solid fa-book-bookmark me-2"></i>Usuarios</h1> }
                      lista= { data }
                      columnas={
                        [
                          { columna: "edit", label: "Editar",
                            form: (e)=>
                            <Modal
                              clave={"actualizarUsuario"+e.id}
                              boton={
                                <i className="fa-solid fa-pencil me-1"
                                  data-toggle="tooltip" data-placement="botton" title={"Actualizar "+e.name}>
                                </i>}
                              titulo={"Actualizar Usuario"}
                              body={
                                <UsuarioForm
                                  objeto={e}
                                  onSave={()=>{
                                    obtenerUsuarios();
                                    document.getElementById("close"+("actualizarUsuario"+e.id)).click();
                                  }}
                                />}
                              footer={<>Verifica antes de guardar ...!</>}
                            />
                          },
                          { columna: "id", label: "ID" },
                          { columna: "name", label: "Nombre" },
                          { columna: "lastname", label: "Apellidos"},
                          { columna: "username", label: "Usuario" },
                          { columna: "extension", label: "Extensión" },
                          { columna: "pass_extension", label: "PassWord" },
                          { columna: "conf", label: "Grupo" },
                          { columna: "status", label: "Estatus" },
                          { columna: "name", label: "Nombre" },
                        ]
                      }
                      id= "id"
                      //selected={(e)=>{  }}
                      /*
                      csvname= "Usuarios "
                      /*
                      resaltarFuncion={(e)=>{ return true}}
                      resaltarColor={(e)=>{ return "rgba(58, 223, 149, 0.25)";  }}*/
                      paginacion={true}
                      elementosPorPagina={15}
                    />
                    </div>
                </div>
            </div>
        </div>                  
      </main>
    </CatalogosPlantilla>
  );
}
export default UsuariosPage;