import React, {useEffect, useState} from "react";
import Crud from "../../../helpers/Crud";

const UsuarioForm =({ objeto, onSave })=> {
  const [data, setData]= useState({
    "name": "",
    //"rfc": "",
    "correo": "",
    //"direction": "",
    "phone": "",
    "status": true
  });
  useEffect(() => {
    if( objeto && 'id' in objeto){
      setData({
        "name": objeto.name,
        //"rfc": objeto.rfc,
        "correo": objeto.correo,
        //"direction": objeto.direction,
        "phone": objeto.phone,
        "status": objeto.status
      });
    }
  },[objeto]);
  return (
    <div className="row g-3 align-items-end">
      <div className="col-lg-12 my-2">
        <div className="form-floating">
            <input type="text" className="form-control" id={"name"+(objeto && objeto.id? objeto.id:"")} placeholder="Nombre"
              value={data.name} onChange={(e) => setData({...data, name: e.target.value})}
            />
            <label htmlFor="name">Nombre</label>
        </div>
      </div>{/*
      <div className="col-lg-12 my-2">
        <div className="form-floating">
            <input type="text" className="form-control" id={"rfc"+(objeto && objeto.id? objeto.id:"")} placeholder="RFC"
              value={data.lastname} onChange={(e) => setData({...data, rfc: e.target.value})}
            />
            <label htmlFor="rfc">RFC</label>
        </div>
      </div>*/}
      <div className="col-lg-12 my-2">
        <div className="form-floating">
            <input type="email" className="form-control" id={"correo"+(objeto && objeto.id? objeto.id:"")} placeholder="name@example.com"
              value={data.correo} onChange={(e) => setData({...data, correo: e.target.value})}
            />
            <label htmlFor="correo">Correo</label>
        </div>
      </div>{/*
      <div className="col-lg-12 my-2">
        <div className="form-floating">
            <input type="text" className="form-control" id={"direction"+(objeto && objeto.id? objeto.id:"")} placeholder="Directión"
              value={data.direction} onChange={(e) => setData({...data, direction: e.target.value})}
            />
            <label htmlFor="direction">Directin</label>
        </div>
      </div>*/}
      <div className="col-lg-12 my-2">
        <div className="form-floating">
            <input type="number" className="form-control" id={"phone"+(objeto && objeto.id? objeto.id:"")} placeholder="0000000000"
              value={data.phone} onChange={(e) => setData({...data, phone: e.target.value})}
            />
            <label htmlFor="phone">Telefono</label>
        </div>
      </div>
      <div className="col-lg-12 my-4">
          <button className="btn btn-primary btn-lg w-100"
            onClick={()=>
              Crud("client"+(objeto && 'id' in objeto? ("/"+objeto.id):""), (objeto && 'id' in objeto? "put":"post"), data, "")
              .then(res => {
                if( onSave ){
                  onSave();
                }
              }).catch(err => {
                console.log("A ocurrido un error intenta de nuevo o mas tarde ..!!");
              })
            }
          >
            <i className="fa-solid fa-save me-1"></i>
            Guardar
          </button>
      </div>
    </div>
  );
}
export default UsuarioForm;