import React, {useEffect, useState} from "react";
import Crud from "../../../helpers/Crud";
import { Modal, TablePageable } from "../../../helpers";
import ClientesForm from "./ClientesForm";
import { CatalogosPlantilla } from "../../../componentes";

const ClientesPage =()=> {
  const [data, setData]= useState([]);
  useEffect(() => {
    obtenerClientes();
  },[]);
  const obtenerClientes=()=>{
    Crud("client", "get", "", "").then(res => {
      if( res && res.length ){
        setData(res);
      }
    }).catch(err => {
      console.log("A ocurrido un error intenta de nuevo o mas tarde ..!!");
    })
  }
  return (
    <CatalogosPlantilla catalogoNombre="Clientes">
      <main className="content">
        <h1 className="title"><i className="fa-solid fa-book-bookmark me-2"></i>Clientes</h1>
        <div className="row my-4">
            <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                        <div className="justify-content-end d-flex">
                          <Modal
                            clave={"nuevoCliente"}
                            boton={
                              <button className="btn btn-primary">
                                <i className="fa-solid fa-circle-plus me-1"></i> Crear cliente
                              </button>}
                            titulo={"Nuevo cliente"}
                            body={
                              <ClientesForm
                                objeto={null}
                                onSave={()=>{
                                  obtenerClientes();
                                  document.getElementById("close"+("nuevoCliente")).click();
                                }}
                              />}
                            footer={<>Verifica antes de guardar ...!</>}
                          />
                        </div>
                    </div>
                  
                    <div className="card-body">
                    <TablePageable
                      lista= { data }
                      columnas={
                        [
                          { columna: "edit", label: "Editar",
                            form: (e)=>
                            <Modal
                              clave={"actualizarCliente"+e.id}
                              boton={
                                <i className="fa-solid fa-pencil me-1"
                                  data-toggle="tooltip" data-placement="botton" title={"Actualizar "+e.name}>
                                </i>}
                              titulo={"Actualizar Cliente"}
                              body={
                                <ClientesForm
                                  objeto={e}
                                  onSave={()=>{
                                    obtenerClientes();
                                    document.getElementById("close"+("actualizarCliente"+e.id)).click();
                                  }}
                                />}
                              footer={<>Verifica antes de guardar ...!</>}
                            />
                          },
                          { columna: "id", label: "ID" },
                          { columna: "name", label: "Nombre" },
                          { columna: "rfc", label: "RFC"},
                          { columna: "correo", label: "Correo" },
                          { columna: "direction", label: "Direction" },
                          { columna: "phone", label: "Teléfono" },
                          { columna: "status", label: "Estatus" },
                        ]
                      }
                      id= "id"
                      paginacion={true}
                      elementosPorPagina={15}
                    />
                    {/**
                              {
                                "id": 0,
                                "name": "string",
                                "rfc": "string",
                                "correo": "string",
                                "direction": "string",
                                "phone": "string",
                                "status": true,
                                "createdAt": "2024-09-09T05:53:15.322Z",
                                "updatedAt": "2024-09-09T05:53:15.322Z"
                              }
                            */}
                    </div>
                </div>
            </div>
        </div>                  
      </main>
    </CatalogosPlantilla>
  );
}
export default ClientesPage;