import React, {useEffect, useState} from "react";
import { CatalogosPlantilla } from "../componentes";
import Crud from "../helpers/Crud";
const DashboardPrincipal =()=> {
  useEffect(() => {
  },[]);
  const handleLogin=()=>{/*
    Crud("auth", "post", credenciales, "").then(res => {
        if( res.access_token ){
          sessionStorage.setItem("token", res.access_token);
          window.location.href="/usuarios";
        }        
      }).catch(err => {
        setMessage("A ocurrido un error intenta de nuevo o mas tarde ..!!");
      });*/
  }
  return (
    <CatalogosPlantilla catalogoNombre="DASHBOARD">
      <main class="content">
          <h1 class="title"><i class="fa-solid fa-house me-2"></i>Dashboard</h1>
          <div class="row g-2 my-4">                   
              <div class="col-lg-3 col-sm-6">
                  <div class="card color-card">
                      <div class="card-body p-0">
                          <div class="row g-0">
                              <div class="col-lg-8 col-8 bg-blue d-flex align-items-center px-2 py-3">Llamadas Entrantes</div>
                              <div class="col-lg-4 col-4 bg-blue-b d-flex align-items-center px-2 py-3 justify-content-center">109849</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="card color-card">
                      <div class="card-body p-0">
                          <div class="row g-0">
                              <div class="col-lg-8 col-8 bg-success-a d-flex align-items-center px-2 py-3">Llamadas Entrantes</div>
                              <div class="col-lg-4 col-4 bg-success-b d-flex align-items-center px-2 py-3 justify-content-center">109849</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="card color-card">
                      <div class="card-body p-0">
                          <div class="row g-0">
                              <div class="col-lg-8 col-8 bg-warning-a d-flex align-items-center px-2 py-3">Llamadas Entrantes</div>
                              <div class="col-lg-4 col-4 bg-warning-b d-flex align-items-center px-2 py-3 justify-content-center">109849</div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6">
                  <div class="card color-card">
                      <div class="card-body p-0">
                          <div class="row g-0">
                              <div class="col-lg-8 col-8 bg-black d-flex align-items-center px-2 py-3">Llamadas Entrantes</div>
                              <div class="col-lg-4 col-4 bg-dark d-flex align-items-center px-2 py-3 justify-content-center">109849</div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="row">
              <div class="col-lg-4">
                  <div class="card">
                      <div class="card-header">
                          <h5 class="card-title mb-0">Almacenamiento</h5>
                      </div>
                      <div class="card-body">
                          
                      </div>
                  </div>
              </div>
              <div class="col-lg-8">
                  <div class="card">
                      <div class="card-header">
                          <h5 class="card-title mb-0">Uso de Sistema</h5>
                      </div>
                      <div class="card-body">                               
                      </div>
                  </div>
              </div>
              <div class="col-lg-6">
                  <div class="card">
                      <div class="card-header">
                          <h5 class="card-title mb-0 lh-1">Llamadas totales por número de semana</h5>
                          <small>Por rango de fechas</small>
                      </div>
                      <div class="card-body">
                          
                      </div>
                  </div>
              </div>

              <div class="col-lg-6">
                  <div class="card">
                      <div class="card-header">
                          <h5 class="card-title mb-0 lh-1">Llamadas totales por horas</h5>
                          <small>Por rango de fechas</small>
                      </div>
                      <div class="card-body">
                          
                      </div>
                  </div>
              </div>
          </div>
      </main>
    </CatalogosPlantilla>
  );
}
export default DashboardPrincipal;