/**
 * DateFormat
 * Recibe como parametro Fecha string y el formato requerido ,retorna Strin formateado
 */
export function DateFormat(rgba){ 
}

// yyyymmddhhmmss
export function timeToDateNumbers(timestamp){ 
  if (timestamp!=null){
  var dat= new Date(timestamp), mes = (dat.getMonth() + 1), dia = dat.getDate(), mesD = "", diaD = "";
  var hora = dat.getHours(), min = dat.getMinutes(), seg = dat.getSeconds();
  mes   < 10 ? mesD = "0" + mes   : mesD  = mes; 
  dia   < 10 ? diaD = "0" + dia   : diaD  = dia;
  hora  < 10 ? hora = "0" + hora  : hora  = hora;
  min   < 10 ? min  = "0" + min   : min   = min;
  seg   < 10 ? seg  = "0" + seg   : seg   = seg;
  

  return dat.getFullYear() + mesD + diaD + hora +min + seg;
  } else {
    return null;
  }
}

// yyyy-mm-dd hh:mm:ss
export function timeToDateFill(timestamp){ 
  var dat= new Date(timestamp), mes = (dat.getMonth() + 1), dia = dat.getDate(), mesD = "", diaD = "";
  var hora = dat.getHours(), min = dat.getMinutes(), seg = dat.getSeconds();
  mes   < 10 ? mesD = "0" + mes   : mesD  = mes; 
  dia   < 10 ? diaD = "0" + dia   : diaD  = dia;
  hora  < 10 ? hora = "0" + hora  : hora  = hora;
  min   < 10 ? min  = "0" + min   : min   = min;
  seg   < 10 ? seg  = "0" + seg   : seg   = seg;


  return dat.getFullYear() + "-" + mesD + "-" + diaD+" "+ hora+":"+min+":"+seg
}
// yyyy-mm-ddThh:mm:ssz
export function timeToDateFillZ(timestamp){ 
  if (timestamp!=null){
  var dat= new Date(timestamp), mes = (dat.getMonth() + 1), dia = dat.getDate(), mesD = "", diaD = "";
  var hora = dat.getHours(), min = dat.getMinutes(), seg = dat.getSeconds();
  mes   < 10 ? mesD = "0" + mes   : mesD  = mes; 
  dia   < 10 ? diaD = "0" + dia   : diaD  = dia;
  hora  < 10 ? hora = "0" + hora  : hora  = hora;
  min   < 10 ? min  = "0" + min   : min   = min;
  seg   < 10 ? seg  = "0" + seg   : seg   = seg;
  

  return dat.getFullYear() + "-" + mesD + "-" + diaD+"T"+ hora+":"+min+":"+seg+"z"
  } else {
    return null;
  }
}
// 00:00:00 to 0000(milisegunbos)
export function timeToMilisegundos(StringTime){
  if(StringTime){
    var data=StringTime.split(':'); //parseInt("10", 10)
    let horas= 0; let minutos= 0; let segundos= 0;  
    if( data[0]>0 ){ horas= data[0] }
    if( data[1]>0 ){ minutos= data[1] }
    if( data[2]>0 ){ segundos= data[2] }  
    horas= horas*60*60;
    minutos= minutos*60;
    return (horas+minutos+segundos)*1000;
  }else{
    return 0;
  }
  
}
// dd-mm-yyyy
export function timeToDatefull(timestamp){ 
  var dat= new Date(timestamp), mes = (dat.getMonth() + 1), dia = dat.getDate(), mesD = "", diaD = "";
  mes   < 10 ? mesD = "0" + mes   : mesD  = mes; 
  dia   < 10 ? diaD = "0" + dia   : diaD  = dia;
  return dat.getFullYear() + "-" + mesD + "-" + diaD
}

// yyyy-mm-dd
export function timeToYearmonthDay(timestamp){ 
  var dat= new Date(timestamp), mes = (dat.getMonth() + 1), dia = dat.getDate(), mesD = "", diaD = "";
  mes   < 10 ? mesD = "0" + mes   : mesD  = mes; 
  dia   < 10 ? diaD = "0" + dia   : diaD  = dia;
  return dat.getFullYear() + "-" + mesD + "-" + diaD
}

//# 4/9/2019
export function timeToDate(timestamp){ return new Date(timestamp).toDateString(); }
export function timeToDatetime(timestamp){ return new Date(timestamp).toLocaleString(); }

//#Wed, 04 Sep 2019 14:52:19 GMT
export function timeToGMTS(timestamp){ return new Date(timestamp).toGMTString(); }

//# 2019-09-04T14:52:19.413Z
export function timeToISOS(timestamp){ return new Date(timestamp).toISOString(); }

//# 2019-09-04T14:52:19.413Z
export function timeToJSON(timestamp){ return new Date(timestamp).toJSON(); }

//# 9:52:19
export function timeToLocaleTime(timestamp){ return new Date(timestamp).toLocaleTimeString(); }

//# 4/9/2019 9:52:19
export function timeToLocale(timestamp){ return new Date(timestamp).toLocaleString(); }

//# Wed Sep 04 2019 09:52:19 GMT-0500 (hora de verano central)
export function timeToString(timestamp){ return new Date(timestamp).toString(); }

//# 09:52:19 GMT-0500 (hora de verano central)
export function timeGetTime(timestamp){ return new Date(timestamp).toTimeString(); }

//# Wed, 04 Sep 2019 14:52:19 GMT
export function timeToUTC(timestamp){ return new Date(timestamp).toUTCString(); }

/*
  var dat= new Date(timestamp), mes = (dat.getMonth() + 1), dia = dat.getDate(), mesD = "", diaD = "";
  mes < 10 ? mesD = "0" + mes : mesD = mes; dia < 10 ? diaD = "0" + dia : diaD = dia;*/

  
  export default DateFormat;